<template>
  <div class="exam-list">
    <div class="title-box">
      <div class="title">
        <div class="dot"></div>
        <div>产品及服务组合信息管理</div>
      </div>
      <div class="title">
        <el-button @click="lookHotGoods">查看商品榜单</el-button>
        <el-button type="primary" @click="addMeal" :disabled="tableConfig.total === bingNum">新增套餐</el-button>
      </div>
    </div>
    <div style="height: calc(100% - 202px)">
      <el-table :data="tableList" height="100%" :header-cell-style="{ fontWeight: 'normal', height: '60px', color: '#666', background: '#F6F6F6', fontSize: '16px' }" :cell-style="{ fontSize: '16px', color: '#333', height: '60px' }">
        <el-table-column prop="name" label="套餐名称"></el-table-column>
        <el-table-column align="right" label="操作" width="200">
          <template slot-scope="scope">
            <div class="link-box">
              <el-link type="success" :underline="false" @click="addMeal(scope.row)">编辑</el-link>
              <el-link type="danger" :underline="false" @click="delMeal(scope.row)">删除</el-link>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="width: 100%; margin-top: 20px; display: flex; justify-content: space-between; align-items: center">
      <div>总共{{ tableConfig.total }}条数据，当前第{{ tableConfig.page }}页</div>
      <el-pagination small background layout="prev, pager, next" v-model:current-page="tableConfig.page" :page-size="tableConfig.page_size" :total="tableConfig.total" @current-change="pageChange" />
    </div>
    <div class="footer-btn" style="margin-top: 40px; text-align: center">
      <el-button type="primary" @click="sureSave">提 交</el-button>
    </div>
  </div>
</template>

<script>
import { goodsPackageList, delGoodsMeal, saveGoodsPackage } from "@/utils/productService";

export default {
  name: "List",
  components: {},
  props: {},
  data() {
    return {
      tableList: [],
      tableConfig: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      bingNum: void 0,
    };
  },
  computed: {},
  methods: {
    getTableList() {
      goodsPackageList({ paging: 1, page: this.tableConfig.page, limit: this.tableConfig.pageSize }).then((res) => {
        this.tableList = res.data.list;
        this.tableConfig.total = res.data.total;
        this.bingNum = res.data.bing_num;
      });
    },
    lookHotGoods() {
      let url = this.$router.resolve({ path: "/student/exam/productSerive/hotGoods" });
      window.open(url.href, "_blank");
    },
    addMeal(data) {
      this.$router.push({ path: "/student/exam/productSerive/operate", query: { id: data ? data.id : void 0 } });
    },
    delMeal(data) {
      this.$confirm(`确定要删除当前套餐吗？ `, "删除套餐提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "green-message-box",
        type: "warning",
      }).then(() => {
        delGoodsMeal({ id: data.id }).then((res) => {
          if (res.code === 200) {
            this.$message.success(res.msg);
            this.getTableList();
          } else {
            this.$message.warning(res.msg);
          }
        });
      });
    },
    pageChange(val) {
      this.tableConfig.page = val;
      this.getTableList();
    },
    sureSave() {
      saveGoodsPackage().then((res) => {
        if (res.code === 200) {
          this.$message.success(res.msg);
          getTableList();
        } else {
          this.$message.warning(res.msg);
        }
      });
    },
  },

  mounted() {
    this.getTableList();
  },
};
</script>

<style scoped lang="scss">
.exam-list {
  box-sizing: border-box;
  height: 100%;
  padding: 48px 30px;
  .title-box {
    margin: 20px 0 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #000;
    font-size: 16px;
    .title {
      display: flex;
      align-items: center;
      .dot {
        width: 4px;
        height: 20px;
        background: #2dc079;
        border-radius: 2px;
        margin-right: 10px;
      }
    }
  }
}
</style>
